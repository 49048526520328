import IconPinLarge from 'images/icons/mapPin/IconPinLarge.svg'
import IconPinSmall from 'images/icons/mapPin/IconPinSmall.svg'
import IconSuperstore from 'images/icons/mapPin/IconSuperstore.svg'
import IconPitstop from 'images/icons/mapPin/IconPitstop.svg'
import IconHome from 'images/icons/mapPin/IconHome.svg'
import IconHomeSec from 'images/icons/mapPin/IconHomeSec.svg'
import {
  SContentIconWrapper,
  SContentWrapper,
  SIconWrapper,
  SLabel,
  SWrapper
} from './MapPin.styled'
import { ReactNode } from 'react'
import { Marker } from '../marker/Marker'

export type MapPinVariant =
  | 'number'
  | 'superstore'
  | 'branch'
  | 'pitstop'
  | 'home'

export type MapPinSize = 'small' | 'large'

interface Props {
  latitude: number
  longitude: number
  label?: string
  variant: MapPinVariant
  size: MapPinSize
  onClick?: () => void
  color?: 'primary' | 'secondary'
  clusterSize?: number
  className?: string
  children?: ReactNode
}

export function MapPin({
  latitude,
  longitude,
  label,
  variant,
  size,
  onClick,
  color = 'secondary',
  clusterSize,
  className,
  children
}: Props) {
  const renderIcon = () => {
    switch (variant) {
      case 'superstore':
        return IconSuperstore
      case 'branch':
        return IconHome
      case 'pitstop':
        return IconHomeSec
      case 'home':
        return IconHomeSec
      default:
        return IconPitstop
    }
  }

  const getOffsets = () => {
    switch (size) {
      case 'large':
        return { offsetLeft: -30, offsetTop: -74 }
      case 'small':
        return { offsetLeft: -14, offsetTop: -32 }
      default:
        return {}
    }
  }

  return (
    <Marker
      latitude={latitude}
      longitude={longitude ?? 0}
      className={className}
      {...getOffsets()}
    >
      <SWrapper onClick={onClick} isClickable={!!onClick}>
        {children}
        <SIconWrapper
          colorVariant={color}
          as={size === 'large' ? IconPinLarge : IconPinSmall}
        />

        {variant === 'number' ? (
          <SContentWrapper>{clusterSize}</SContentWrapper>
        ) : (
          <SContentIconWrapper
            colorvariant={color}
            as={renderIcon()}
            variant={variant}
            size={size}
          />
        )}

        {label && <SLabel variant="caption">{label}</SLabel>}
      </SWrapper>
    </Marker>
  )
}
