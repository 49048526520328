import dynamic from 'next/dynamic'
import { ComponentType } from 'react'

type MarkerProps = {
  longitude: number
  latitude: number
  offsetLeft?: number
  offsetTop?: number
  className?: string
}

export const Marker: ComponentType<MarkerProps> = dynamic(
  () => import('react-map-gl').then(module => module.Marker),
  {
    ssr: false
  }
)
