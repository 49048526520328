import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { color } from 'driverama-core/styles/variables'
import { MapPinSize, MapPinVariant } from './MapPin'
import { ResponsiveValue } from 'driverama-core/styles/responsivity'
import { size } from 'driverama-core/styles/spacing'
import { TextBodyMedium, TextHeader } from 'driverama-core/components/text/Text'

export const SWrapper = styled.div<{ isClickable?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  ${({ isClickable }) =>
    isClickable &&
    css`
      @media (hover) {
        &:hover {
          cursor: pointer;
        }
      }
    `}
`

export const SIconWrapper = styled.svg<{
  colorVariant: 'primary' | 'secondary'
}>`
  display: flex;
  color: ${({ colorVariant }) =>
    colorVariant === 'primary' ? color('night-l-100') : color('sun')};
`

export const SContentIconWrapper = styled.svg<{
  variant?: ResponsiveValue<MapPinVariant>
  size?: ResponsiveValue<MapPinSize>
  colorvariant: 'primary' | 'secondary'
}>`
  color: ${({ colorvariant }) =>
    colorvariant === 'primary' ? color('white') : color('sun-glow-dark')};

  position: absolute;
  top: ${size(3.5)};

  width: ${size(8)};
  height: ${size(8)};

  ${({ size: pinSize }) =>
    pinSize === 'small' &&
    css`
      top: ${size(1.5)};

      width: ${size(4)};
      height: ${size(4)};
    `}
`

export const SContentWrapper = styled.svg<{
  variant?: ResponsiveValue<MapPinVariant>
  size?: ResponsiveValue<MapPinSize>
}>`
  color: ${color('sun-glow-dark')};

  position: absolute;
  top: 14px;

  font-size: ${size(8)};

  ${({ size: pinSize }) =>
    pinSize === 'small' &&
    css`
      top: ${size(1.5)};

      font-size: ${size(4)};
    `}
`

export const SNumberWrapper = styled(TextHeader)<{ size?: 'small' | 'large' }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    calc(-50% - ${({ size }) => (size === 'small' ? '1px' : '6px')})
  );

  color: ${color('sun-glow-dark')};
  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 14px;
      line-height: 18px;
    `}
`

export const SLabel = styled(TextBodyMedium)`
  color: ${color('black')};

  /* generated with https://owumaro.github.io/text-stroke-generator/ */
  text-shadow: rgb(255, 255, 255) 2px 0px 0px,
    rgb(255, 255, 255) 1.75517px 0.958851px 0px,
    rgb(255, 255, 255) 1.0806px 1.68294px 0px,
    rgb(255, 255, 255) 0.141474px 1.99499px 0px,
    rgb(255, 255, 255) -0.832294px 1.81859px 0px,
    rgb(255, 255, 255) -1.60229px 1.19694px 0px,
    rgb(255, 255, 255) -1.97998px 0.28224px 0px,
    rgb(255, 255, 255) -1.87291px -0.701566px 0px,
    rgb(255, 255, 255) -1.30729px -1.5136px 0px,
    rgb(255, 255, 255) -0.421592px -1.95506px 0px,
    rgb(255, 255, 255) 0.567324px -1.91785px 0px,
    rgb(255, 255, 255) 1.41734px -1.41108px 0px,
    rgb(255, 255, 255) 1.92034px -0.558831px 0px;

  font-size: 13px;
  line-height: 16px;
  min-width: 80px;
  max-width: 90px;
  text-align: center;
  position: absolute;
  top: 100%;
`
